<div class="page">

    <div class="page__headline">
        <span eciTranslate="app.masterArticleJob.title">Master Article Job</span>
        <span eciTranslate="global.forCountry"
              translateValues="{country: '{{ country.name }}'}">for {{ country.name }}</span>
    </div>

    <div class="page__grid mb-4">
        <div>
            <dx-file-uploader
                #fileUploader
                uploadUrl=""
                [multiple]="false"
                accept=".xlsx"
                allowCanceling="true"
                uploadMode="useForm"
                id="fileUploaderId"
                (onValueChanged)="uploadFile($event)"
                [(value)]="fileName"
                [width]="400"
            ></dx-file-uploader>
            <dx-button (onClick)="updateDeliveryTimeForArticles()" [hidden]="!fileToUpload"
                       [text]="this.translateService.get('app.masterArticleJob.fileUploader.create')"
                       type="success" useSubmitBehavior="false" class="saveButton"></dx-button>
            <dx-button (onClick)="changeDatasource()" [hidden]="!fileToUpload"
                       [text]="this.translateService.get('app.masterArticleJob.fileUploader.search')"
                       type="success" useSubmitBehavior="false" class="searchButton" style="margin: 5px"></dx-button>
            <dx-button (onClick)="resetFileUploader()" [hidden]="!fileToUpload"
                       [text]="this.translateService.get('app.masterArticleJob.fileUploader.remove')"
                       class="clearButton" style="margin: 5px">
            </dx-button>
        </div>
        <dx-data-grid id="masterArticleGrid" #masterArticleGrid
                      (onEditorPrepared)="onEditorPreparing($event)"
                      (onInitNewRow)="onNewEntry($event)"
                      (onRowInserted)="successNotification()"
                      (onRowUpdated)="successNotification()"
                      (onRowPrepared)="onRowPrepared($event)"
                      [allowColumnReordering]="true"
                      [allowColumnResizing]="true"
                      [columnAutoWidth]="true"
                      [dataSource]="masterArticleDatasource"
                      [remoteOperations]="true"
                      [showBorders]="true"
                      [showColumnLines]="true"
                      [showRowLines]="true"
                      [wordWrapEnabled]="true"
                      [keyExpr]="'erpNumber'"
                      [(selectedRowKeys)]="selectedRows"
                      (onSelectionChanged)="onSelectionChanged()"
                      (onOptionChanged)="onOptionChanged($event)"
                      (onContentReady)="onContentReady()"
                      (onToolbarPreparing)="onToolbarPreparing($event)"
                      class="h-50" style="height: 100px;width: fit-content">
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-paging [pageSize]="20"></dxo-paging>
            <dxo-load-panel [enabled]="true"></dxo-load-panel>
            <dxo-state-storing
                [enabled]="true"
                type="custom">
            </dxo-state-storing>
            <dxo-selection
                [showCheckBoxesMode]="'always'"
                [allowSelectAll]="false"
                [selectAllMode]="'page'"
                [deferred]="false"
                mode="multiple">
                <dxo-pager
                    [allowedPageSizes]="[10, 20, 50, 100]"
                    [showInfo]="true"
                    [showPageSizeSelector]="true">
                </dxo-pager>
            </dxo-selection>
            <dxo-pager
                [allowedPageSizes]="[10, 20, 50, 100]"
                [showInfo]="true"
                [showPageSizeSelector]="true"
                [showNavigationButtons]="true">
            </dxo-pager>
            <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
            <dxo-editing
                [allowAdding]="true"
                [allowDeleting]="true"
                [allowUpdating]="true"
                [useIcons]="true"
                mode="popup">
                <dxo-popup
                    [height]="525"
                    [showTitle]="true"
                    [width]="700"
                    [title]="'app.masterArticleJob.title' | translate">
                </dxo-popup>
                <dxo-form
                    [colCount]="1"
                    [elementAttr]="{ class: 'bank-holidays_edit' }">
                    <dxi-item
                        cssClass="master_article_job_edit_item"
                        dataField="erpNumber"
                        dataType="string">
                    </dxi-item>
                    <dxi-item
                        cssClass="master_article_job_edit_item"
                        dataField="deliveryTime"
                        dataType="number">
                        <dxi-validation-rule
                            type="pattern"
                            [pattern]="'^[0-9]\\d*$'"
                            [message]="'app.masterArticleJob.confirmationDialog.incorrect' | translate">>
                        </dxi-validation-rule>
                    </dxi-item>
                </dxo-form>
            </dxo-editing>
            <dxi-column
                [caption]="'app.masterArticleJob.columns.erpNumber' | translate"
                [minWidth]="150"
                dataField="erpNumber"
                dataType="string"
                sortOrder="desc">
                <dxi-validation-rule type="required" message="ERP Number is required"></dxi-validation-rule>
            </dxi-column>
            <dxi-column
                [caption]="'app.masterArticleJob.columns.deliveryTime' | translate"
                [minWidth]="250"
                dataField="deliveryTime"
                dataType="number">
                <dxi-validation-rule type="required" message="Delivery time is required"></dxi-validation-rule>
                <dxi-validation-rule type="numeric" message="Delivery time is a positive number"></dxi-validation-rule>
                <dxi-validation-rule type="range" message="Delivery time starts from 1" [min]="1" [max]="60"></dxi-validation-rule>
            </dxi-column>
            <dxi-column
                [calculateFilterExpression]="dataGridUtilService.calculateFilterExpression"
                [caption]="'app.masterArticleJob.columns.createdAt' | translate"
                [minWidth]="120"
                dataField="createdAt"
                dataType="datetime">
            </dxi-column>
            <dxi-column
                [calculateFilterExpression]="dataGridUtilService.calculateFilterExpression"
                [caption]="'app.masterArticleJob.columns.updatedAt' | translate"
                [minWidth]="120"
                dataField="updatedAt"
                dataType="datetime">
            </dxi-column>
            <dxi-column type="buttons" width="100">
                <dxi-button name="edit"></dxi-button>
                <dxi-button name="delete"></dxi-button>
            </dxi-column>
            <div *dxTemplate="let data of 'totalGroupCount'">
                <div class="informer">
                    <h2 class="count">{{ this.getTotalRowCount() }}</h2>
                    <span
                        class="name">{{ this.translateService.get('app.deliveryProduct.toolbar.selectedArticles') }}</span>
                </div>
            </div>
        </dx-data-grid>
    </div>
</div>
